<template>
  <div class="stakewait">
    <!-- notification start -->
    <!-- <div class="marquee">
      <Marquee :duration="15">{{ $t("network.notification") }}</Marquee>
    </div> -->
    <!-- notification end -->


    <div class="top_button">
      <div class="coinInfomation" @click="isShown2 = true">
        <div class="coinImg"><img :src="swap_aimg" class="dailogo" /></div>
        <div class="coinInfo">{{ swap_Aname }} Stablecoin</div>
        <img :src="swap_arrow" class="swaparrow" />
      </div>
    </div>

    <Divider style="background: rgba(142, 200, 243, 0.1)" />
    <!-- 激活 -->
    <div style="margin-bottom: 50px;" v-if="userIsActivevalue == false">
      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("stake.Balance") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
          {{ formattedDaiOwnMoney3 }}<img class="daimi" :src="swap_falcon" style="height: 20px;width: 20px;" />
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("stake.Activeaccount") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">
          {{ formattedDaiOwnMoney4 }}

          <img class="daimi" :src="swap_falcon" style="height: 20px;width: 20px;" />
        </div>
      </div>
      <div class="StakeAuthor" style="margin-top: 20px;">
        <div class="Author">

          <button :disabled="empowerbutton2" :class="[!empowerbutton2 ? 'StakeBtn' : 'enabled-class']" @click="Approve2"
            v-if="typeApprove2 == 0">
            {{ $t("stake.Appoove") }}
          </button>
          <button class="StakeBtn" v-if="typeApprove2 == 1">
            {{ $t("stake.Appooveing") }}
          </button>
          <!-- appoove button end -->
        </div>

        <div class="Stakeing">
          <button class="StakeBtn ripple" @click="doActivate()" v-if="typedeposit == 0 && empowerbutton2 == true">
            {{ $t("stake.active") }}
          </button>
          <button class="StakeBtn" disabled v-else>{{ $t("stake.active") }}</button>
        </div>


      </div>
    </div>
    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("stake.Balance") }}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
      </div>
    </div>
    <div class="inputInfo">
      <input v-model.number="daiValue" type="number" class="inputStyle" @input="validateAmount" />
      <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button>
    </div>
    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("stake.Total") }}:</div>
      <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
        {{ formattedDaiOwnMoney2 }}

        <img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
      </div>
    </div>
    <!-- <hr class="hr1" /> -->
    <!-- <div class="tvl"><span>TVL</span>:{{ needinvest }}M</div> -->
    <!-- <div class="tvl"><span>TVL</span>:14M</div>
    <div class="currP">
      <ProgressBar :progress="currentProgress"></ProgressBar>
    </div> -->
    <div class="StakeAuthor">
      <div class="Author">
        <!-- appoove button start-->
        <!-- <button :disabled="empowerbutton" class="StakeBtn ripple" @click="empower" v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }}
        </button> -->


        <button :disabled="empowerbutton" :class="[!empowerbutton ? 'StakeBtn' : 'enabled-class']" @click="empower"
          v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }}
        </button>

        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>

      <div class="Stakeing">

        <button class="StakeBtn" v-if="userIsActivevalue == false" disabled>{{ $t("stake.Stake") }}</button>
        <button :disabled="depositDisabled" class="StakeBtn " @click="stake" v-else-if="typedeposit == 0">
          {{ $t("stake.Stake") }}
        </button>
        <button class="StakeBtn" v-else>{{ $t("stake.Stakeing") }}</button>
      </div>


    </div>

    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <div class="selectCoin" v-show="isShown2">
      <div class="tipsandclose">
        <div class="t1">{{ $t("swap.recommend") }}（Polygon）</div>
        <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
      </div>
      <div class="allconin">
        <div class="coinBTN" @click="onselect(swap_usdt, 'USDT', 'USDT_swap', '6')">
          <img :src="swap_usdt" alt="">
          <div class="coiname">USDT</div>
        </div>
        <div class="coinBTN" @click="onselect(swap_usdc, 'USDC', 'USDC_swap', '6')">
          <img :src="swap_usdc" alt="">
          <div class="coiname">USDC</div>
        </div>
        <!-- @click="onselect(swap_dai, 'DAI', 'DAI_swap', '18')" -->
        <div class="coinBTN">
          <img :src="swap_dai" alt="">
          <div class="coiname">DAI</div>
        </div>
        <!-- <div class="coinBTN" @click="onselect(swap_matic, 'MATIC', 'MATIC', '18')">
                            <img :src="swap_matic" alt="">
                            <div class="coiname">MATIC</div>
                        </div> -->
        <!-- @click="onselect(swap_falcon,'FALCON','falcon_swap','18')" -->
        <!-- <div class="coinBTN" @click="onselect(swap_falcon,'FALCON','falcon_swap','18')">
                            <img :src="swap_falcon" alt="">
                            <div class="coiname">FALCON</div>
                        </div> -->


        <!-- @click="onselect(swap_dai, 'USDS', 'USDC_swap', '18')" -->
        <div class="coinBTN">
          <img :src="swap_dai" alt="">
          <div class="coiname">USDS</div>
        </div>
      </div>
    </div>
    <!--loading end-->
  </div>
</template>
<style scoped src="@/assets/css/EvStake.css"></style>
<style lang="less" scoped></style>
<script>

import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import ProgressBar from "@/components/ProgressBar.vue";
// import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import price from "@/plugins/price.js";
import sign from "@/plugins/sign.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import axios from "axios";
export default {
  data() {
    return {
      swap_usdc,
      swap_usdt,
      swap_falcon,
      swap_matic,
      swap_dai,
      swap_arrow,
      swap_close,
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typeApprove2: 0,
      typedeposit: 0,
      typedeposit2: 0,
      dai: dai,
      daimni: daimni,
      loadingimg: loadingimg,
      daiValue: "",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      empowerbutton2: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      list1: [],
      list2: [],
      pricelist1: [],
      pricelist2: [],
      isShown2: false,
      swap_decimalsA: 18,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      swapimg2: "",
      tokenUsersinvestAmount: 0,
      userIsActivevalue: false,
      daiOwnMoneyjh: 0,
      hasActivatevalue: 0
    };
  },
  components: {
    // Marquee,
    //ProgressBar,
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({ path: "/" });
            return;
          }
        })
        .catch((error) => {
          console.error("Failed to obtain network information. Procedure:", error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(this.addresscode)
      // );
      this.signtype()


      //  console.log(this.daiOwnMoney.toFixed(6));


      // this.exchangeallowance()
    }
  },
  methods: {
    onactive() {
      this.$router.push({ path: '/active' })
    },
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {

          if (res.code == 0) {
            this.users();
            // this.exchangeallowance();
            this.limitAllInvestAmount();
            this.minInvestAmount();
          }
          this.isloading = false;
        });
    },

    // tb地址接口
    getindex() {
      axios
        .post(this.sitedata.url.address + '/api/index/retuentokens', {

        })
        .then(res => {

          this.list1 = res.data.data.getUserTotalTokenValue
          this.list2 = res.data.data.getUsdtPrices
          this.getUserTotalTokenValue()

        })
    },

    async getUserTotalTokenValue() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.price_token.address;
      await price
        .getUserTotalTokenValue(
          this.sitedata.aave_token.address,
          ContractAddress,
          _decimals,
          this.list1
        )
        .then((res) => {

          this.pricelist1 = res.data
          this.getUsdtPrices()
        });
    },
    async getUsdtPrices() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.price_token.address;
      await price
        .getUsdtPrices(
          this.sitedata.aave_token.address,
          ContractAddress,
          _decimals,
          this.list2
        )
        .then((res) => {

          this.pricelist2 = res.data
          var values = 0
          for (let index = 0; index < this.pricelist2.length; index++) {
            var value = this.pricelist2[index] * this.pricelist1[index]

            values += value
          }

          // this.currentProgress = ((values * 1) / this.pricetotal) * 100;
          this.currentProgress = ((values * 1) / 14000000) * 100;
          this.currentProgress = this.currentProgress.toFixed(2) * 1;
          // this.currentProgress = (this.currentProgress + 2.47).toFixed(2) * 1;
          // this.currentProgress = (this.currentProgress - 5.9).toFixed(2) * 1;

        });
    },

    async getdata() {
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        // this.daiOwnMoney = parseFloat(
        //   await web3Utils.getDaiBalance(this.addresscode)
        // );

        //console.log(this.daiOwnMoney.toFixed(6));
        this.users();
        this.limitAllInvestAmount();
        this.minInvestAmount();
        //this.exchangeallowance()
      }
    },
    validateAmount() {

      if (this.daiValue * 1 < this.Minimal) {
        this.isButtonDisabled = true;
        this.depositDisabled = true;
      } else {
        this.isButtonDisabled = false;
        this.depositDisabled = false;
        this.exchangeallowance();
      }
    },
    // Authorization button
    empower() {
      this.isloading = true;  // open loading
      this.Approve();
    },
    // contract Approve
    async Approve() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.swap_Aaddress;
      spender = this.sitedata.aave_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton = true;
            this.validateAmount();
            this.userIsActive()
          } else {

            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    // 激活授权
    async Approve2() {
      this.isloading = true;  // open loading
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.falcon_swap.address
      spender = this.sitedata.aave_token.address;
      this.typeApprove2 = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove2 = 0;
          if (res.code == 0) {
            this.typeApprove2 = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton2 = true;

            this.userIsActive()
          } else {

            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    // View the number of authorized contracts
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.swap_Aaddress;
      spender = this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        this.isloading = false;
        this.userIsActive()
        if (result.data * 1 >= 10000) {
          this.empowerbutton = true;
        } else {
          this.empowerbutton = false;
        }
        if (this.daiValue <= result.data * 1) {
          this.depositDisabled = false;
        } else {
          this.depositDisabled = true;
        }
      });
    },
    // 验证激活是否授权
    async exchangeallowance2() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      spender = this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        this.isloading = false;
        this.userIsActive()
        if (result.data * 1 >= 10000) {
          this.empowerbutton2 = true;
        } else {
          this.empowerbutton2 = false;
        }

      });
    },
    // 选择币种
    async onselect(img, name, site, _decimals, swaptype) {
      this.swap_decimalsA = _decimals
      this.swap_aimg = img
      this.swap_Aname = name
      this.swap_Aaddress = this.sitedata[site].address
      console.log(this.sitedata[site].address, 'AAAAAAAAAAAAAA')
      this.balance()
      this.exchangeallowance()
      this.tokenUsers()
      this.balance2()
      this.hasActivate()

      this.isShown2 = false

    },
    // 余额a
    async balance(site) {
      var that = this
      let _decimals = this.swap_decimalsA;
      let spender;
      let Address;
      Address = this.swap_Aaddress
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,

        _decimals
      ).then((result) => {
        console.log(result, "余额A");

        this.daiOwnMoney = result.data
        this.isloading = false;

      });
    },
    // 激活余额
    async balance2() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,

        _decimals
      ).then((result) => {
        console.log(result, "激活余额");
        this.daiOwnMoneyjh = result.data
        this.isloading = false;

      });
    },
    // 激活账户
    async hasActivate() {
      this.isloading = true;
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .hasActivate(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,

        )
        .then((res) => {

          console.log(res, 'hasActivate')
          this.hasActivatevalue = res.data

        });
    },
    // tokenUsers
    async tokenUsers() {
      this.isloading = true;
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {

          console.log(res, 'tokenUsers')
          this.tokenUsersinvestAmount = res.data.investAmount

        });
    },
    // Contract depositToken
    async depositToken() {


      let _decimals = this.swap_decimalsA;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typedeposit = 1;
      try {
        await contract
          .depositToken(
            Address, //Contract address
            this.daiValue, //quantity
            this.addresscode, //Current address
            _decimals, //Fractional part
            this.swap_Aaddress
          )
          .then((res) => {
            // console.log(res);
            this.typedeposit = 0;
            this.isloading = false;
            if (res.code == 0) {
              this.typedeposit = 0;
              this.$Notice.success({
                title: this.$t("stake.StakeSuccessful")
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this.getdata();
              this.balance()
            } else {

              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("stake.StakeFailed")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }
          });
      } catch (error) {

        this.$Notice.warning({
          title: this.$t("stake.StakeFailed")
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },
    // Need investment
    async limitAllInvestAmount() {
      //  console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      // console.log("Need investment");
      ContractAddress = this.sitedata.aave_token.address;
      // await contract
      //   .limitAllInvestAmount(
      //     this.addresscode, //Current user address
      //     ContractAddress, //Call contract
      //     _decimals
      //   )
      // .then((res) => {
      //   console.log(res, "limitAllInvestAmount");
      //   this.needinvest = (res.data * 1) / 1000000;
      //   this.pricetotal = res.data * 1
      //   this.getindex()
      //   // this.allInvestAmount(res.data);
      // });
    },
    // invested
    async allInvestAmount(value) {
      // console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      // console.log("invested");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .allInvestAmount(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //  console.log(res, "invested");
          const total = value * 1;
          //  console.log(total);
          this.currentProgress = ((res.data * 1) / total) * 100;
          this.currentProgress = this.currentProgress.toFixed(2) * 1;

          // this.currentProgress = (this.currentProgress - 5.9).toFixed(2) * 1;

        });
    },
    // Minimum total input
    async minInvestAmount() {
      // console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      // console.log("Minimum total input");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .minInvestAmount(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //      console.log(res, "Minimum total input");
          this.Minimal = res.data;
        });
    },
    // User information
    async users() {

      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          this.isloading = false;
          this.usersdata = res.data;
          this.stakedMoney = res.data.investAmount * 1;


          this.swap_Aaddress = this.sitedata.USDT_swap.address
          this.swapimg2 = this.swap_usdt
          this.swap_aimg = this.swap_usdt
          this.swap_Aname = 'USDT'
          this.swap_decimalsA = 6
          this.balance()
          this.exchangeallowance()
          // this.nowDepositWithdrawToken()

          this.tokenUsers()
          this.balance2()
          this.hasActivate()
          this.exchangeallowance2()

        });
    },
    async userIsActive() {

      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .userIsActive(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,

        )
        .then((res) => {
          console.log(res, 'userIsActive')
          this.userIsActivevalue = res.data
        });
    },
    async nowDepositWithdrawToken() {
      this.isloading = true;
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .nowDepositWithdrawToken(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {
          this.isloading = false;
          console.log(res, 'nowDepositWithdrawToken', this.sitedata)
          var value = res.data.toLowerCase()
          console.log(value, 'valuevaluevaluevalue', this.sitedata.USDC_swap.address)

          this.swap_Aaddress = value
          if (value == this.sitedata.DAI_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_dai
            this.swap_aimg = this.swap_dai
            this.swap_Aname = 'DAI'
            this.swap_decimalsA = 18
          } else if (value == this.sitedata.USDT_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdt
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.swap_decimalsA = 6
          } else if (value == this.sitedata.USDC_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdc
            this.swap_aimg = this.swap_usdc
            this.swap_Aname = 'USDC'
            this.swap_decimalsA = 6
          }
          this.balance()
          this.exchangeallowance()
          // this.stakedMoney = res.data.investAmount * 1;
        });
    },
    // Stake button
    stake() {
      if (this.daiValue * 1 < this.Minimal) {
        this.$Notice.warning({
          title: this.$t("stake.StakeInfo") + this.Minimal
        });
        return;
      }
      this.isloading = true; // isloading open
      this.depositToken();
      // this.warning(false);
    },
    // Contract doActivate
    async doActivate() {

      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typedeposit2 = 1;
      try {
        await contract
          .doActivate(
            Address, //Contract address
            this.daiValue, //quantity
            this.addresscode, //Current address
            _decimals, //Fractional part

          )
          .then((res) => {
            console.log(res);
            this.typedeposit2 = 0;
            this.isloading = false;
            if (res.code == 0) {
              this.typedeposit2 = 0;
              this.$Notice.success({
                title: '激活成功'
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this.getdata();
              this.balance()
            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: '激活失败'
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }
          });
      } catch (error) {
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: '激活失败'
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("network.stake"),
        //desc: nodesc ? "" : this.$t("network.stake"),
      });
    },
  },
  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.0000";

      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);


      return value
    },
    formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    formattedDaiOwnMoney3() {
      if (!this.daiOwnMoneyjh) return "0.0000";

      const multipliedNumber = this.daiOwnMoneyjh * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);


      return value
    },
    formattedDaiOwnMoney4() {
      if (!this.hasActivatevalue) return "0.0000";
      const multipliedNumber = this.hasActivatevalue * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
  },
};
</script>
